import consumer from "./consumer"

consumer.subscriptions.create("ChatChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    //console.log('connected websocket active cable channel')
  },

  disconnected() {
     //Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    //console.log("recieved data", data)
    setElementWebSocketMessageChat(data.content)
  }
});
